import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import Img from 'gatsby-image';
import arrow from 'img/ui/arrow_white.svg';

const HeatMappingTool = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query HeatMappingTool {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "heatmaps/heat-mapping-tool" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2> 8 reasons why it’s worth using a heat mapping tool for your website</h2>
      <p>
        Heat map tracking brings various advantages to your business – from website auditing, all
        the way through to making more informed business decisions. Let’s now discuss them in more
        detail.{' '}
      </p>
      <h3>1. A quick audit of your website user experience </h3>
      <p>
        Heat mapping tools are a great starting point to auditing your website, as they provide you
        with a bird’s eye view of your users’ on-site interactions. While it may initially seem like
        a complicated or high tech tool, it’s actually a type of analysis technique that website
        owners have been using for years. It gives you a visual representation of your website’s
        performance, which is easier to understand than data scattered across charts or tables.{' '}
      </p>
      <p>
        These types of UX tools give you the inside scoop of your website’s performance so that you
        can fix any possible issues and increase usability. And, as{' '}
        <Link href="/solutions#usability-testing" target="_blank" rel="noopener noreferrer">
          usability
        </Link>{' '}
        is increased, so will the user engagement.
      </p>
      <p>
        The best part? Heat mapping tools are often very affordable, if not completely free. Be sure
        to look online for free heat maps or tools that offer a{' '}
        <Link href="/" target="_blank" rel="noopener noreferrer">
          free trial
        </Link>
        .{' '}
      </p>
      <h3>2. Nailing down any usability issues</h3>
      <p>
        When people go onto a website to view a company’s products or services, they are typically
        in a hurry and don’t want to spend a lot of time scrolling through content to find what they
        are looking for. If you uncover that users spend at least{' '}
        <Link
          href="https://www.searchenginejournal.com/dwell-time-seo/294471/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          two minutes on your site
        </Link>
        , you can assume that they find the content on it useful. However, if you see that the
        bounce rates are above average (as indicated on the graphic below), consider using a heat
        map tracking tool to investigate the issue.{' '}
      </p>
      <Img
        fluid={images.kissmetrics.node.childImageSharp.fluid}
        alt="using heat map tracking to reduce bounce rate"
        title="Nailing down any usability issues"
      />
      <p>
        {' '}
        Source:{' '}
        <Link
          href="https://neilpatel.com/blog/bounce-rate/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Kissmetrics
        </Link>{' '}
      </p>
      <p>
        Furthermore, heat maps will also allow you to identify elements on your website that are
        clicked on the most by users. These can be both actual links and elements that visitors
        think are clickable, and – after not being taken to another webpage – interpret as a bug or
        broken link. In the end, if an element is not contributing to the overall experience, it is
        simply a distraction and does not need to be there.{' '}
      </p>
      <h3>3. Boost on-site engagement</h3>
      <p>
        Through the use of heat map tracking, you can receive special insight into users’ activities
        and can view which parts of the site they interacted with the most.
      </p>
      <p>
        Your goal is to keep a user engaged with your website for as long as possible (or at least
        as long as it takes for them to respond to your call to action). Heat maps help you to
        determine if you need to rearrange your content so that the most valuable pieces are more
        accessible. This leads us to the next point...
      </p>
      <h3>4. Making sure users always see the important content</h3>
      <p>
        There will be times when users click through your website but become idle before they ever
        see the most important information you have to offer. Scroll maps are a great way to avoid
        this. They show how far down your website visitors scroll before losing interest. This will
        allow you to determine if they have to go through too much content before finding what they
        are looking for. The coloring in a scroll map also helps to convey whether a user found what
        they were looking for by reaching the desired section or gave up and decided to leave the
        website.{' '}
      </p>
      <Img
        fluid={images.unsplash1.node.childImageSharp.fluid}
        alt="using heat map tracking to reduce bounce rate"
        title="Nailing down any usability issues"
      />
      <p>
        {' '}
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/KiEiI2b9GkU"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>{' '}
      </p>

      <p>
        Once you have this knowledge, you can adjust your content architecture and make sure that
        the most important elements are not buried under filler content. You certainly do not want
        critical information placed in a location that is only ever reached by a very small
        percentage of users.{' '}
      </p>
      <h3>5. Understanding user actions and on-site decisions</h3>
      <p>
        It’s quite straightforward – the better you understand your users’ actions on your website,
        the better you’ll be able to tailor your customer journeys and on-site architecture.
      </p>

      <p>
        Click maps are an example of a heat map tool that gives you amazing insights into user
        intent. Some of the questions you’ll be able to find the answers to by setting it up on your
        website include:
      </p>
      <ul>
        <li>Does your audience predominantly click on buttons, links, or pictures? </li>
        <li>Are there any elements that are rarely being clicked on at all? </li>
        <li>Do people misinterpret unclickable images and think they are hyperlinked?</li>
        <li>
          Do people click on a button of lesser business importance (i.e., instead of “sign up
          free”, they click on “schedule a demo call”)?
        </li>
      </ul>
      <p>
        {' '}
        When you uncover any unusual click-related behavior, you should do your research and figure
        out why this is the case. You don’t want two different buttons competing against one another
        to accomplish the same goal. If a button is getting clicked on more than a crucial CTA on
        your website, consider removing it.{' '}
      </p>

      <p>
        If you notice that your users are primarily clicking on images to move throughout your site,
        be sure to incorporate more of them and remove elements that are not being utilized as much.{' '}
      </p>
      <h3>6. Identify best places for CTAs to boost conversion</h3>
      <p>
        If you run a for-profit website, then its absolute most important goal is having users sign
        up or finalize their purchase. Heat maps help you to identify the best place to place your
        CTA.{' '}
        <Link href="/heatmaps/heatmap-analysis/" target="_blank" rel="noopener noreferrer">
          This can be done with scroll maps, hover maps, and eye-tracking maps (we address this
          topic in more detail in another article).
        </Link>{' '}
      </p>
      <p>
        For example, as in the case of other important on-site content, scroll maps will show you
        how long users have to scroll before they first view your call to action. If they have to
        sift through too much information before reaching it,{' '}
        <Link
          href="https://www.interaction-design.org/literature/article/information-overload-why-it-matters-and-how-to-combat-it"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          they might become frustrated
        </Link>{' '}
        and leave. Utilize this information by rearranging elements and bringing your call to action
        to the forefront. For example, if your call to action is “buy now”, be sure to place that
        button in a location with a 100% scroll rate so that it won’t be overlooked.{' '}
      </p>
      <h3>7. Give context to behavioral data from other tools </h3>
      <p>
        The more you understand your users and their activity, the more equipped you will be to
        deliver them the best experience possible. The perfect website is not built overnight. It
        takes lots of trial and error before you can figure out what works and what doesn’t.
        Nevertheless, heat mapping tools can help make the process a lot easier by showing you the
        behavioral patterns of your users.{' '}
      </p>

      <p>
        What annoys or distracts them? Are certain elements frustrating or confusing? Listen to your
        audience and the way they respond to your content. Once you understand them better, you can
        offer a better experience.{' '}
      </p>
      <h3>8. Make informed business decisions</h3>
      <p>
        Heat maps help you to understand who your audience is. If your website is the bread and
        butter of your business, it is crucial that it operates at maximum efficiency. The bottom
        line is that the impact of heat maps on UX is huge. It’s a highly effective tool that allows
        you to better understand customers and their behavior, analyze what is and isn’t effective
        about your site, and make the necessary changes to ensure you&apos;re getting the results
        you need to bring your company forward.{' '}
      </p>
      <Img
        fluid={images.unsplash2.node.childImageSharp.fluid}
        alt="the impact of heat maps on UX"
        title="Make informed business decisions"
      />
      <p>
        {' '}
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/7okkFhxrxNw"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>{' '}
      </p>
      <h2>Summary </h2>
      <p>
        In conclusion, utilizing heat mapping tools is a great way to optimize and maintain your
        website’s high usability. User behavior is constantly changing and what might have worked
        before may not be working now. The only way you can truly be on top of your game is by
        understanding your audience, how they interact with your content, and making whatever
        necessary changes are needed to give them the best experience possible. Don’t underestimate
        the impact of heat maps on UX, and do your business a favor by utilizing a free heat mapping
        tool today!
      </p>

      <div className="next-chapter">
        <Link href="/heatmaps/heat-map-software/">
          <div>
            <p>Chapter 7</p>
            <p>How to use heat map software with other tools</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(HeatMappingTool);
